import React from 'react'

const styles = {
    div: {
        display: 'flex',
        justifyContent: 'center',
        margin: '1rem'
    }
}

export default function Loader() {
    return (
        <div style={styles.div}>
            <img src="images/loader.svg" alt="loader"/>
        </div>
    )
}


