import React from 'react'
import './Modal.css'

export default class Modal extends React.Component {
    //do not useing hooks, just using classic React state
    state = {
        isOpen: false
    }

    render() {
        {/*React.Fragment чтобы реакт не добавлял своего кода */}
        return (
            
            <React.Fragment>
                <button onClick={() => this.setState({isOpen: true})}>Open modal</button>
                {
                    //means if state isOpen ===  true then...
                    this.state.isOpen && ( 
                        <div className="modal">
                        <div className="modal-body">
                            <h1>Title</h1>
                            <button onClick={() => this.setState({isOpen: false})}>&times;</button>
                        </div>
                        </div>
                    )
                }
                
                
            </React.Fragment>
        )
    }
}