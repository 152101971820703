import React, {useContext} from 'react'
import PropTypes from 'prop-types'
//for remove function we need to add
import Context from '../context' 

const styles = {
    li: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '.5rem 1rem',
        borderTop: '1px solid #ccc',
        marginBottom: '1rem'
    },
    input: {
        marginRight: '1rem'
    }     
}

function TodoItem({todo, number, doSomething}) { //we can write 'props' and than get 'props.todo', but if we know variable name we can write directly '{todo}'
    //get function from App
    const {buttonRemoveTodo} = useContext(Context); 

    const classes = [];
    if (todo.completed) classes.push('done');

    return (
        //in event we must describe 'callback' function
        //was
        //<input style={styles.input} type="checkbox" onChange={() => console.log(todo.id)} /> 

        //'buttonRemoveTodo' we can load this function 2 variants:
        //() => buttonRemoveTodo(todo.id) - as callback
        //buttonRemoveTodo.bind(null, todo.id) - or like this
        //buttonRemoveTodo(todo.id) - we can't use just like this, because this function well be launched when TodoItem will be launched
        <li style={styles.li}>
            <span className={classes.join(' ')}>
                
                <input style={styles.input} checked={todo.completed} type="checkbox" onChange={() => doSomething(todo.id)} /> 
                <b>{number + 1}</b>&nbsp;{todo.title}
            </span>
            <button className='btn-remove' onClick={buttonRemoveTodo.bind(null, todo.id)}>&times;</button> 
            
        </li> //need to install 'npm install prop-types'
    )
}

//if we get key 'number' like 'number.toString()' we get warning!
TodoItem.propTypes = {
    todo: PropTypes.object.isRequired,
    number: PropTypes.number,
    doSomething: PropTypes.func.isRequired
}

//new for validation
export default TodoItem