import React from 'react'
import PropTypes from 'prop-types' //need for vars validating
import TodoItem from './TodoItem'

const styles = {
    ul: {
        listStyle: 'none',
        margin: 0,
        padding: 0
    }
}

//was
//export default function TodoList(props) {
function TodoList(props) {    
    // console.log(props.todos); //this string will working dinamically only after added 'setTodos()'
    return (
        //<TodoItem todo={{title: 'test'}}/>
        <ul style={styles.ul}>
            {
                props.todos.map((el, idx) => {
                    //for warning raise 'idx.toString()'
                    return <TodoItem todo={el} key={el.id} number={idx} doSomething={props.onToggle}/> //each elements must be unique, so must add 'key'
                })
            }
        </ul>
    );
}

//need for get vars correctly
TodoList.protoTypes = {
    //was
    //todos: PropTypes.array
    todos: PropTypes.arrayOf(PropTypes.object).isRequired,
    onToggle: PropTypes.func.isRequired
} 

export default TodoList