import React, {useEffect} from 'react'
import TodoList from './Todo/TodoList'
import Context from './context'
import Modal from './Modal/Modal'


//without lazy loading
//import AddTodo from './Todo/AddTodo'
import Loader from './Loader'

//if we want to load weighty component (need commit import AddTodo from './Todo/AddTodo')
const AddTodo = React.lazy(() => import('./Todo/AddTodo')); //now we have constant which dinamicaly loading our component

function App() {
  //реакт должен перерендерить (перерисовать компонент), для этого нужно использовать hooks
  /*const q = React.useState(
    [
      {id: 1, completed: false, title: 'buy bread'},
      {id: 2, completed: false, title: 'go to the shop'},
      {id: 3, completed: false, title: 'clean kittchen'},
    ]
  );*/ //sending initial state. this function always returm array from two elements: 1 state, 2 function for changing this state
  //so changing to: 
  let [todos, setTodos] = React.useState(
    [
      {id: 1, completed: false, title: 'buy bread'},
      {id: 2, completed: false, title: 'go to the shop'},
      {id: 3, completed: true, title: 'clean kittchen'},
    ]
  );
  //if we reading info from external json we need to clear array

  //for loading adding new state
  const [loading, setLoading] = React.useState(true) //true because we always show loading on start

  //read data from server
  useEffect(() => {
    fetch('test.json')
    .then(response => response.json())
    //example
    //.then(json => console.log(json))
    .then(todos => {
      //just for test
      setTimeout(
        () => {
          setTodos(todos); //server waiting emulation 
          setLoading(false); //hiding loader
        }, 4000
      )
      //setTodos(todos)
    })
  }, []) //second param IMPORTANT must be empty array, empty, becuase must do something just one time

  //was before const q = React.useState();
  /*let todos = [
    {id: 1, completed: false, title: 'buy bread'},
    {id: 2, completed: false, title: 'go to the shop'},
    {id: 3, completed: false, title: 'clean kittchen'},
  ];*/

  function toggleTodo(id) {
    //was for test
    //console.log('current id is', id);
    
    //was before changing state
    /*
    todos = todos.map(todo => {
      if (todo.id === id) todo.completed = !todo.completed;
      return todo;
      }
    )
    */
    //from this function we changing main array
    //after this React starting see this changing
    setTodos(
      todos = todos.map(todo => {
        if (todo.id === id) todo.completed = !todo.completed;
        return todo;
        }
      )
    )
  }

 //added after Context.Provider 
  function removeTodo(id) {
    setTodos(
      todos.filter(todo => todo.id !== id) //leave just elements which not equal ID
    )
  }

  function addTodo(title) {
    //add new array row
    setTodos(todos.concat([
      {
        title,
        id: Date.now(),
        completed: false
      }])
    )
  }

  return (
    /*was before Context
    <div className="wrapper">
        <h1>React first project</h1>
        <TodoList todos={todos} onToggle={toggleTodo}/>
    </div>
    */
    //this is for sending function throw all
    //in value I can send whatever I want
    <Context.Provider value={{buttonRemoveTodo: removeTodo}}>
      <div className="wrapper">
          <h1>React first project</h1>
          
          <Modal />

          <React.Suspense fallback={<p>Loading...</p>}>
            <AddTodo onCreate={addTodo}/>
          </React.Suspense>
          
          {/*
          <AddTodo onCreate={addTodo}/> */}

          {loading ? <Loader /> : ''}

          {todos.length>0 ? <TodoList todos={todos} onToggle={toggleTodo}/> : loading ? null : <p>array is empty!</p>}
      </div> 
    </Context.Provider>
  );
}

export default App;
